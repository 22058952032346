.sidebar {
    height: inherit;
    padding-left: 31px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background-color: #fff;
    position: relative;
}

.logoBox {
    height: 100px;
    display: flex;
    overflow: hidden;
    align-items: center;
}

.logoBox h3 {
    font-size: 20px;
    font-weight: 600;
    color: #7A40F2F2;
    display: flex;
    padding-top: 15px;
    gap: 3px;
    transition: all 0.6s ease;
    white-space: nowrap;
}

.logoBox .logoIcon {
    color: #171717;
    border: 1px solid #7A40F2F2;
    width: 1.5rem;
    height: 1.5rem;
    padding: 4px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
}

.logoBox h3 p {
    gap: 3px;
    align-items: center;
    white-space: nowrap;
}

.logoBox h3 span {
    color: #171717;
    font-weight: 800;
}

.menubar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 0 0 0;
    justify-content: flex-start;
    flex: 1;
}

.menubar li {
    list-style: none;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.menubar .menubarLink {
    text-decoration: none;
    color: #171717;
    display: flex;
    align-items: center;
    gap: 12px;
    transition: all 0.5s ease;
    overflow: hidden;
    height: auto;
    width: 100%;
    min-width: 200px;
}
.menubar .menubarLink span {
    transition: all 0.4s ease;
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.menubar .menubarLink span::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid #aa8de5f2;
    transition: all 0.3s ease;
    display: none;
}

.menubar .menubarLink:hover span::before {
    display: block;
    left: -5px;
}


.menubar .menubarLink:hover span {
    background-color: #aa8de5f2;
    padding: 2px 10px;
    border-radius: 8px;
    color: #fff;
}

.menubar .menubarLink:hover>.iconList {
    color: #fff;
}

.logoutBox {
    height: 50px;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    transition: all 0.5s ease;
    min-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 20px;
}


.logoutBox span {
    transition: all 0.4s ease;
    position: relative;
    white-space: nowrap;
}

.logoutBox:hover span {
    background-color: #aa8de5f2;
    padding: 2px 10px;
    border-radius: 8px;
    color: #fff;
}

.logoutBox span::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid #aa8de5f2;
    transition: all 0.3s ease;
    display: none;
}

.logoutBox:hover span::before {
    display: block;
    left: -5px;
}

.logoutBox:hover>.iconList {
    color: #fff;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #171717;
    cursor: pointer;
}

.iconList {
    width: 1.75rem;
    height: 1.75rem;
    color: #171717;
    padding: 5px;
    border-radius: 8px;
    background-color: #aa8de5f2;
    cursor: pointer;
    transition: all 0.5s ease;
}

.active {
    color: #7A40F2F2;
}


/* @media only screen and (max-width: 768px){
    .sidebar{
        position: absolute;
        left: 0;
        height: 100%;
    }
} */