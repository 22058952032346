.dataContainer {
  background-color: #f2f8fd;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;
}

.text {
  padding: 0px 10px;
}

.text p {
  color: #8f92a1;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.welcome {
  background-color: #7a40f2f2;
  border-radius: 20px;
  padding: 10px 20px;
  min-height: 172px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.welcomeText {
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.welcomeText h2 {
  font-size: 26px;
  line-height: 53.5px;
  font-weight: 400;
}

.welcomeText h2 span {
  font-weight: 600;
}

.welcomeText p {
  font-size: 13px;
  line-height: 19.5px;
}

.link {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  line-height: 20px;
}

.icon {
  width: 1rem;
  height: 1rem;
  transition: all 0.5s ease;
}

.link:hover > .icon {
  transform: rotate(-45deg);
}

.welcome .imgWrapper {
  height: 172px;
  padding: 0 10px;
}

.welcome .imgWrapper img {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.box {
  flex: 1 1 300px;
  min-height: 130px;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s ease;
}

.box:hover {
  box-shadow: 0 0 5px #aa8de5f2;
}
